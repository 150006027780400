.panelTableStyle {
    padding-top: 10px !important;
    //--panel-table-auto-row-width: minmax(auto, 600px);
    --panel-table-label-column-width: 500px;
    --panel-table-gap: 20px;
    *[class*='even_row'] {
        background-color: #fcfcfd;
        border: none !important;
    }

    *[class*='odd_row'] {
        background-color: #f1f3f6;
        border: none !important;
    }

    *[class*='rowValue'] {
        text-align: center;
    }
}

.panelTableCompanies {
    *[class*='grid_row_1 '] {
        background-color: transparent !important;
    }
}
.companyHeaderElement {
    background-color: white;
    border-radius: 20px;
    height: 200px;
    width: calc(100% - 40px);
    margin: 10px 20px;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
}

.companyNameLabel {
    font-size: 20px;
    font-family: LustTextMedium;
    color: black;
}

.panelTitle {
    background-color: transparent !important;
    *::after {
        content: '';
        height: 5px;
        width: 100%;
        background-image: linear-gradient(
            to right,
            black 33%,
            rgba(255, 255, 255, 0) 0%
        );
        background-position: bottom;
        background-size: 10px 1px;
        background-repeat: repeat-x;

        position: absolute;
        bottom: 0px;
        left: 0px;
    }
}
