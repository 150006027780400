.container {
    padding-left: var(--main-container-side-padding);
    padding-right: var(--main-container-side-padding);
    background-color: #fcfcfd !important;
    padding-top: 15px;
}

.tabsShadow {
    width: 100%;
    height: 20px;
    left: 0;
    top: 150px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, #fcfcfd 100%);
    position: absolute;
    transform: translateY(45px);
    z-index: 10000;
}
