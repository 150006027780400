.thumb {
    min-width: 650px;
    max-width: 1500px;
    flex-basis: 1;
    flex-grow: 1;
    height: 400px;
    padding: 30px;
    margin-left: 0;
    margin-right: 10px;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 15px;
    cursor: pointer;
    position: relative;
    &:hover {
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
    }
}

.divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #e0e0e0;
    margin-bottom: 15px;
}

.title {
    font-size: 40px;
    font-family: Hanken Grotesk;
    font-weight: 600;
}

.filenameText {
    font-size: 30px;
    font-family: Hanken Grotesk;
    font-weight: 600;
}
