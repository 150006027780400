.container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100000;
    margin: auto;
    background-color: '#EAEAEA';
    border-radius: 20px;
}

.scrollFadeContent {
    &::before {
        border-radius: 10px;
        content: '';
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 50px;
        background-image: linear-gradient(white 20%, transparent 100%);
        top: 0;
    }
}

.messages {
    background-color: '#EAEAEA';
    height: calc(100% - 60px);
}

.input {
    height: 100%;
    font-size: 20px;
    flex-grow: 1;

    input {
        &:focus {
            border: none !important;
            border-top: 1px solid #7c7c7c !important;
        }

        outline: 0;
        background-color: transparent;
        border: none !important;
        border-top: 1px solid #e0e0e0 !important;
        padding: 5px 10px 5px 10px;
        margin-left: 10px;
        width: 100%;
    }
}

.sendButton {
    width: 100px;
    font-family: Hanken Grotesk !important;
    font-size: 20px !important;
    font-weight: bold;
    border-radius: 0 0 20px 0 !important;
}

.inputLineOffset {
    transform: translateY(16px);
}
