@import '../../../utils/global.module.less';

.panelRowTitle {
    font-family: LustTextMedium;
    font-weight: 300;
    padding: @title-padding;
    font-style: italic;
    font-size: 22px;
    color: black;
}

.panelTopBorder {
    border-top: 2px solid #eaecf0;
    border-left: 2px solid #eaecf0;
    border-right: 2px solid #eaecf0;
}
