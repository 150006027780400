.dealViewTabs {
    --bs-nav-underline-gap: 50px !important;
    --bs-nav-underline-link-active-color: #957f32 !important;
    --bs-emphasis-color: #957f32 !important;
    padding-left: var(--main-container-side-padding) !important;
    padding-right: var(--main-container-side-padding) !important;
    background-color: white;
    &.nav .nav-item .nav-link {
        color: #102442;
        background-color: transparent;
        font-family: Hanken Grotesk;

        &:hover {
            border-bottom-color: transparent;
            border-bottom-color: rgba(147, 124, 40, 0.63);
        }

        &.active {
            border-bottom-color: #957f32;
        }
    }
}
