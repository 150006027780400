@import '../../../utils/global.module.less';

.selected {
    color: #007bff !important;
    font-weight: bold !important;
}

.industrySearch {
    min-width: 300px;
    flex-grow: 1;
    & * {
        font-size: 16px !important;
        font-family: Hanken Grotesk !important;
        border-radius: 10px !important;
        margin: 0 !important;
    }

    & *[class*='btn-close'] {
        box-shadow: none !important;
    }

    & *[class*='rbt-input-multi'] {
        padding: 6px !important;
    }

    & *[class*='rbt-input-main'] {
        padding: 12px !important;
    }

    & *[class*='rbt-input-wrapper'] {
        height: 45px !important;
    }

    & *[class*='rbt-menu dropdown-menu'] {
        transform: translateY(60px) !important	;
    }

    & *[class*='rbt-token'] {
        font-size: 14px !important;
        font-family: Hanken Grotesk !important;
        height: 45px !important;
        align-content: center !important;
        text-align: center !important;
        &:not(:first-child) {
            margin-left: 5px !important	;
        }

        &:first-child {
            margin-left: 0px !important;
        }
    }

    & *[class*='rbt-token-remove-button'] {
        transform: translate(0, 15px) scale(1.5);
    }
    margin-left: 20px !important;
}

.scoreSearch {
    width: 200px;
    height: 60px;
    & * {
        font-size: 16px !important;
        font-family: Hanken Grotesk !important;
        border-radius: 10px !important;
        margin: 0 !important;
    }

    & *[class*='btn-close'] {
        box-shadow: none !important;
    }

    & *[class*='rbt-input-multi'] {
        padding: 6px !important;
    }

    & *[class*='rbt-input-main'] {
        padding: 12px !important;
    }

    & *[class*='rbt-input-wrapper'] {
        height: 45px !important;
    }

    & *[class*='rbt-menu dropdown-menu'] {
        transform: translateY(60px) !important	;
    }

    & *[class*='rbt-token'] {
        font-size: 14px !important;
        font-family: Hanken Grotesk !important;
        height: 45px !important;
        align-content: center !important;
        text-align: center !important;
        &:not(:first-child) {
            margin-left: 5px !important	;
        }

        &:first-child {
            margin-left: 0px !important;
        }
    }

    & *[class*='rbt-token-remove-button'] {
        transform: translate(0, 15px) scale(1.5);
    }
    margin-left: 20px !important;
}

.title {
    font-size: 40px;
    font-family: LustTextMedium;
}

.header {
    //background-color: white;
    height: 80px;
    margin-bottom: 10px;
    margin-top: 30px;
    //border-radius: 0 0 20px 20px;
}

.search {
    width: 200px;
    font-size: 16px;
    font-family: Hanken Grotesk;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
}

.searchBar {
    height: 60px;
    margin-bottom: 20px;
}
