@import '../../utils/global.module.less';
.container {
    padding: @side-padding;
    padding-top: 20px;
    padding-bottom: 15px;
    background-color: white;
    padding-left: var(--main-container-side-padding);
    padding-right: var(--main-container-side-padding);
}

.clientName {
    font-family: LustTextMedium;
    font-size: 25px;
    padding-right: 10px;
    transform: translateY(5px);
}
.shadow {
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
}

.headerRow {
    padding-top: 10px;
}
.scorePosition {
    margin-left: 50px;
    transform: scale(1) !important;
}

.title {
    font-size: 40px;
    font-family: LustTextMedium;
}

.newDealButton {
    width: 120px;
    height: 50px;
}

.plusIcon {
    &::before {
        transform: translate(0, 3px) !important;
        font-size: 20px;
    }
}
