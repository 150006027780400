.thumb {
    min-width: 650px;
    max-width: 1500px;
    flex-basis: 1;
    flex-grow: 1;

    height: 400px;
    padding: 30px;
    margin-left: 0;
    margin-right: 10px;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 15px;
    cursor: pointer;
    position: relative;
    &:hover {
        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.3));
    }
}

.imageThumbnailContainer {
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
    margin-left: 20px;
}

.imageThumbnail {
    width: 200px;
    height: calc(200 * 0.75);
    border-radius: 20px;
    transition: 0.3s;
}

.imageThumbLoading {
    width: 200px;
    height: calc(200px * 0.75);
    border-radius: 20px;
    border: 1px dashed #e0e0e0;
    background-color: white;
    position: absolute;
    z-index: 10000;
}

.score {
    position: absolute;
    top: 30px;
    right: 15px;
}

.overview {
    font-size: 16px;
    font-family: Hanken Grotesk;
}

.title {
    font-size: 24px;
    font-family: Hanken Grotesk;
    font-weight: 600;
}

.industry {
    font-size: 16px;
    font-family: Hanken Grotesk;
    padding-bottom: 10px;
}

.fieldContainer {
    padding-right: 40px;
}

.fieldTitle {
    font-size: 18px;
    font-family: Hanken Grotesk;
}

.fieldValue {
    font-size: 18px;
    font-family: Hanken Grotesk;
    font-weight: 600;
}

.divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #e0e0e0;
    margin-bottom: 15px;
}
