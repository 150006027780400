@import '../../utils/global.module.less';

.newDealButton {
    height: 50px;
    width: 120px;
    position: absolute;
    right: 0;
}

.taskPageDropZone {
    width: 124px;
    height: 54px;

    background-color: transparent !important;
    border: 2px solid white !important;
    transform: translate(-4px, 1px);
    &:hover {
        color: rgb(126, 126, 126) !important;
    }

    transition: border 0.24s ease-in-out;

    &[class*='dz-drag-hover'] {
        border: 2px dashed #007bff !important;
    }

    *[class*='dz-message'] {
        margin: 0 !important;
    }

    border-radius: 10px;
}

.uploadIcon {
    &::before {
        transform: scale(3.1) translate(2px, 5px);
        pointer-events: none;
    }
}
