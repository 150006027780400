@import '../../../utils/global.module.less';

.title {
    font-size: 40px;
    font-family: LustTextMedium;
}

.header {
    background-color: #fcfcfd !important;
    //padding-left: var(--main-container-side-padding);
    //padding-right: var(--main-container-side-padding);
    //padding-bottom: 20px;
    padding-top: 10px;
}

.seperatorFields {
    margin-left: 5px;
    margin-right: 5px;
    transform: translateY(2px);
    color: #d0d5dd !important;
}
