.switchButton {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 10px !important;
    z-index: 10000000;
}

.draggable {
    cursor: col-resize !important;
}

.dragIconStyle {
    transform: scale(0.9);
    position: absolute;
    z-index: 50000;
    transform: translate(-5px, 30px);
}
