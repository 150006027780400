.vb520q_thumb {
  cursor: pointer;
  background-color: #fff;
  border-radius: 20px;
  flex-grow: 1;
  flex-basis: 1px;
  min-width: 650px;
  max-width: 1500px;
  height: 400px;
  margin-bottom: 15px;
  margin-left: 0;
  margin-right: 10px;
  padding: 30px;
  position: relative;
}

.vb520q_thumb:hover {
  filter: drop-shadow(0 0 10px #0000004d);
}

.vb520q_divider {
  border-bottom: 1px dashed #e0e0e0;
  width: 100%;
  height: 1px;
  margin-bottom: 15px;
}

.vb520q_title {
  font-family: Hanken Grotesk;
  font-size: 40px;
  font-weight: 600;
}

.vb520q_filenameText {
  font-family: Hanken Grotesk;
  font-size: 30px;
  font-weight: 600;
}

.zbMiIq_container {
  background-color: var(--content-background-color);
  padding-left: var(--main-container-side-padding);
  padding-right: var(--main-container-side-padding);
}

.rbt .rbt-input-main::-ms-clear {
  display: none;
}

.rbt-menu {
  margin-bottom: 2px;
}

.rbt-menu > .dropdown-item {
  text-overflow: ellipsis;
  overflow: hidden;
}

.rbt-menu > .dropdown-item:focus {
  outline: none;
}

.rbt-menu-pagination-option {
  text-align: center;
}

.rbt-input-multi {
  cursor: text;
  position: relative;
  overflow: hidden;
}

.rbt-input-multi.focus {
  color: #495057;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.rbt-input-multi.form-control {
  height: auto;
}

.rbt-input-multi.disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.rbt-input-multi.is-invalid.focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.rbt-input-multi.is-valid.focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.rbt-input-multi input::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.rbt-input-multi input:-ms-input-placeholder {
  color: #6c757d;
}

.rbt-input-multi input::-webkit-input-placeholder {
  color: #6c757d;
}

.rbt-input-multi .rbt-input-wrapper {
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: -1px;
  margin-bottom: -4px;
  display: flex;
  overflow: hidden;
}

.rbt-input-multi .rbt-input-main {
  margin: 1px 0 4px;
}

.rbt-close {
  z-index: 1;
}

.rbt-close-lg {
  font-size: 1.5rem;
}

.rbt-token {
  color: #007bff;
  background-color: #e7f4ff;
  border: 0;
  border-radius: .25rem;
  margin: 1px 3px 2px 0;
  line-height: 1rem;
  display: inline-flex;
}

.rbt-token .rbt-token-label {
  padding: .25rem .5rem;
}

.rbt-token .rbt-token-label:not(:last-child) {
  padding-right: .25rem;
}

.rbt-token-disabled {
  color: #495057;
  pointer-events: none;
  background-color: #0000001a;
}

.rbt-token-removeable {
  cursor: pointer;
}

.rbt-token-active {
  color: #fff;
  background-color: #007bff;
  outline: none;
  text-decoration: none;
}

.rbt-token .rbt-token-remove-button {
  box-shadow: none;
  color: inherit;
  font-size: inherit;
  opacity: 1;
  text-shadow: none;
  background-image: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  outline: none;
  justify-content: center;
  padding: .25rem .5rem .25rem 0;
  font-weight: normal;
  display: flex;
}

.rbt-token .rbt-token-remove-button .rbt-close-content {
  display: block;
}

.rbt-aux {
  pointer-events: none;
  justify-content: center;
  align-items: center;
  width: 2rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.rbt-aux-lg {
  width: 3rem;
}

.rbt-aux .rbt-close {
  pointer-events: auto;
  margin-top: -.25rem;
}

.has-aux .form-control {
  padding-right: 2rem;
}

.has-aux .form-control.is-valid, .has-aux .form-control.is-invalid {
  background-position: right 2rem center;
  padding-right: 4rem;
}

.rbt-highlight-text {
  background-color: inherit;
  color: inherit;
  padding: 0;
  font-weight: bold;
}

.input-group > .rbt {
  flex: 1;
}

.input-group > .rbt .rbt-input-hint, .input-group > .rbt .rbt-aux {
  z-index: 5;
}

.input-group > .rbt:not(:first-child) .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .rbt:not(:last-child) .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.JH1BnG_panelImage {
  height: 100%;
  width: calc(100% - 20px) !important;
}

.JH1BnG_defaultPadding {
  padding: 20px;
}

.JH1BnG_shadow {
  box-shadow: .2px .3px 1.4px #00000009, .5px .8px 3.8px #0000000d, 1.2px 1.8px 9px #0001;
}

.JH1BnG_dropShadow {
  filter: drop-shadow(3px 4px 5px #00000026);
}

.JH1BnG_selected {
  color: #007bff !important;
  font-weight: bold !important;
}

.JH1BnG_industrySearch {
  flex-grow: 1;
  min-width: 300px;
  margin-left: 20px !important;
}

.JH1BnG_industrySearch * {
  border-radius: 10px !important;
  margin: 0 !important;
  font-family: Hanken Grotesk !important;
  font-size: 16px !important;
}

.JH1BnG_industrySearch [class*="btn-close"] {
  box-shadow: none !important;
}

.JH1BnG_industrySearch [class*="rbt-input-multi"] {
  padding: 6px !important;
}

.JH1BnG_industrySearch [class*="rbt-input-main"] {
  padding: 12px !important;
}

.JH1BnG_industrySearch [class*="rbt-input-wrapper"] {
  height: 45px !important;
}

.JH1BnG_industrySearch [class*="rbt-menu dropdown-menu"] {
  transform: translateY(60px) !important;
}

.JH1BnG_industrySearch [class*="rbt-token"] {
  text-align: center !important;
  align-content: center !important;
  height: 45px !important;
  font-family: Hanken Grotesk !important;
  font-size: 14px !important;
}

.JH1BnG_industrySearch [class*="rbt-token"]:not(:first-child) {
  margin-left: 5px !important;
}

.JH1BnG_industrySearch [class*="rbt-token"]:first-child {
  margin-left: 0 !important;
}

.JH1BnG_industrySearch [class*="rbt-token-remove-button"] {
  transform: translate(0, 15px)scale(1.5);
}

.JH1BnG_scoreSearch {
  width: 200px;
  height: 60px;
  margin-left: 20px !important;
}

.JH1BnG_scoreSearch * {
  border-radius: 10px !important;
  margin: 0 !important;
  font-family: Hanken Grotesk !important;
  font-size: 16px !important;
}

.JH1BnG_scoreSearch [class*="btn-close"] {
  box-shadow: none !important;
}

.JH1BnG_scoreSearch [class*="rbt-input-multi"] {
  padding: 6px !important;
}

.JH1BnG_scoreSearch [class*="rbt-input-main"] {
  padding: 12px !important;
}

.JH1BnG_scoreSearch [class*="rbt-input-wrapper"] {
  height: 45px !important;
}

.JH1BnG_scoreSearch [class*="rbt-menu dropdown-menu"] {
  transform: translateY(60px) !important;
}

.JH1BnG_scoreSearch [class*="rbt-token"] {
  text-align: center !important;
  align-content: center !important;
  height: 45px !important;
  font-family: Hanken Grotesk !important;
  font-size: 14px !important;
}

.JH1BnG_scoreSearch [class*="rbt-token"]:not(:first-child) {
  margin-left: 5px !important;
}

.JH1BnG_scoreSearch [class*="rbt-token"]:first-child {
  margin-left: 0 !important;
}

.JH1BnG_scoreSearch [class*="rbt-token-remove-button"] {
  transform: translate(0, 15px)scale(1.5);
}

.JH1BnG_title {
  font-family: LustTextMedium;
  font-size: 40px;
}

.JH1BnG_header {
  height: 80px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.JH1BnG_search {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  width: 200px;
  padding: 10px;
  font-family: Hanken Grotesk;
  font-size: 16px;
}

.JH1BnG_searchBar {
  height: 60px;
  margin-bottom: 20px;
}

.KZ9tcG_scoreBox {
  background-color: green;
  border: 1px solid #fff;
  border-radius: 20px;
  height: 42px;
  padding: 5px 0;
  transform: scale(.8);
}

.KZ9tcG_icon {
  color: #fff;
  width: 32px;
  margin-left: 10px;
  font-size: 20px;
  transform: scale(1.5)translateY(-1px);
}

.KZ9tcG_text {
  color: #fff;
  align-self: left;
  margin-right: 6px;
  font-size: 20px;
  font-weight: 600;
  transform: translateY(-2px);
}

.KZ9tcG_numberScore {
  color: #000;
  text-align: center;
  background-color: #fff;
  border: none;
  border-radius: 20px;
  justify-content: right;
  align-self: center;
  width: 40px;
  margin: 6px;
  font-size: 18px;
  font-weight: 600;
  transform: translateY(-1px);
}

._4we_0W_thumb {
  cursor: pointer;
  background-color: #fff;
  border-radius: 20px;
  flex-grow: 1;
  flex-basis: 1px;
  min-width: 650px;
  max-width: 1500px;
  height: 400px;
  margin-bottom: 15px;
  margin-left: 0;
  margin-right: 10px;
  padding: 30px;
  position: relative;
}

._4we_0W_thumb:hover {
  filter: drop-shadow(3px 3px 2px #0000004d);
}

._4we_0W_imageThumbnailContainer {
  filter: drop-shadow(2px 2px 2px #0000004d);
  margin-left: 20px;
}

._4we_0W_imageThumbnail {
  border-radius: 20px;
  width: 200px;
  height: 150px;
  transition: all .3s;
}

._4we_0W_imageThumbLoading {
  z-index: 10000;
  background-color: #fff;
  border: 1px dashed #e0e0e0;
  border-radius: 20px;
  width: 200px;
  height: 150px;
  position: absolute;
}

._4we_0W_score {
  position: absolute;
  top: 30px;
  right: 15px;
}

._4we_0W_overview {
  font-family: Hanken Grotesk;
  font-size: 16px;
}

._4we_0W_title {
  font-family: Hanken Grotesk;
  font-size: 24px;
  font-weight: 600;
}

._4we_0W_industry {
  padding-bottom: 10px;
  font-family: Hanken Grotesk;
  font-size: 16px;
}

._4we_0W_fieldContainer {
  padding-right: 40px;
}

._4we_0W_fieldTitle {
  font-family: Hanken Grotesk;
  font-size: 18px;
}

._4we_0W_fieldValue {
  font-family: Hanken Grotesk;
  font-size: 18px;
  font-weight: 600;
}

._4we_0W_divider {
  border-bottom: 1px dashed #e0e0e0;
  width: 100%;
  height: 1px;
  margin-bottom: 15px;
}

._9ntroq_trashIcon {
  margin-left: 30px;
  transform: translateY(10px);
}

._9ntroq_trashIcon:hover {
  color: #8b0000;
}

._9ntroq_thumb {
  cursor: pointer;
  background-color: #fff;
  border-radius: 20px;
  flex-grow: 1;
  flex-basis: 1px;
  min-width: 650px;
  max-width: 1500px;
  height: 400px;
  margin-bottom: 15px;
  margin-left: 0;
  margin-right: 10px;
  padding: 30px;
  position: relative;
}

._9ntroq_thumb:hover {
  filter: drop-shadow(0 0 10px #0000004d);
}

._9ntroq_title {
  font-family: Hanken Grotesk;
  font-size: 40px;
  font-weight: 600;
}

._9ntroq_unlockIcon {
  font-size: 20px;
}

._9ntroq_unlock {
  color: #333;
  width: 60px;
  margin-bottom: 20px;
  font-family: Hanken Grotesk;
  font-weight: 600;
}

._9ntroq_input {
  color: #333;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  width: 300px;
  height: 50px;
  margin-bottom: 20px;
  padding: 0 15px;
  font-size: 16px;
}

._9ntroq_input:focus {
  border: 1px solid #333;
}

._sVDwa_panelImage {
  height: 100%;
  width: calc(100% - 20px) !important;
}

._sVDwa_defaultPadding {
  padding: 20px;
}

._sVDwa_shadow {
  box-shadow: .2px .3px 1.4px #00000009, .5px .8px 3.8px #0000000d, 1.2px 1.8px 9px #0001;
}

._sVDwa_dropShadow {
  filter: drop-shadow(3px 4px 5px #00000026);
}

._sVDwa_container {
  padding: 20px 20px 15px;
  padding-left: var(--main-container-side-padding);
  padding-right: var(--main-container-side-padding);
  background-color: #fff;
}

._sVDwa_clientName {
  padding-right: 10px;
  font-family: LustTextMedium;
  font-size: 25px;
  transform: translateY(5px);
}

._sVDwa_shadow {
  filter: drop-shadow(0 4px 10px #0000001a);
}

._sVDwa_headerRow {
  padding-top: 10px;
}

._sVDwa_scorePosition {
  margin-left: 50px;
  transform: scale(1) !important;
}

._sVDwa_title {
  font-family: LustTextMedium;
  font-size: 40px;
}

._sVDwa_newDealButton {
  width: 120px;
  height: 50px;
}

._sVDwa_plusIcon:before {
  font-size: 20px;
  transform: translate(0, 3px) !important;
}

.dropzone {
  position: relative;
}

.dropzone .dz-preview {
  width: 120px;
  margin: .5em;
  display: inline-block;
  position: relative;
}

.dropzone .dz-preview .dz-progress {
  border: 1px solid #aaa;
  height: 15px;
  display: block;
}

.dropzone .dz-preview .dz-progress .dz-upload {
  background: green;
  width: 0;
  height: 100%;
  display: block;
}

.dropzone .dz-preview .dz-error-message {
  color: red;
  display: none;
}

.dropzone .dz-preview.dz-error .dz-error-message, .dropzone .dz-preview.dz-error .dz-error-mark, .dropzone .dz-preview.dz-success .dz-success-mark {
  display: block;
}

.dropzone .dz-preview .dz-error-mark, .dropzone .dz-preview .dz-success-mark {
  width: 54px;
  height: 58px;
  margin-left: -27px;
  display: none;
  position: absolute;
  top: 30px;
  left: 50%;
}

@keyframes passing-through {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  30%, 70% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.1);
  }

  20% {
    transform: scale(1);
  }
}

.dropzone, .dropzone * {
  box-sizing: border-box;
}

.dropzone {
  border: 1px solid #000c;
  border-radius: 5px;
  min-height: 150px;
  padding: 20px;
}

.dropzone.dz-clickable {
  cursor: pointer;
}

.dropzone.dz-clickable * {
  cursor: default;
}

.dropzone.dz-clickable .dz-message, .dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}

.dropzone.dz-started .dz-message {
  display: none;
}

.dropzone.dz-drag-hover {
  border-style: solid;
}

.dropzone.dz-drag-hover .dz-message {
  opacity: .5;
}

.dropzone .dz-message {
  text-align: center;
  margin: 3em 0;
}

.dropzone .dz-message .dz-button {
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: none;
  border: none;
  padding: 0;
}

.dropzone .dz-preview {
  vertical-align: top;
  min-height: 100px;
  margin: 16px;
  display: inline-block;
  position: relative;
}

.dropzone .dz-preview:hover {
  z-index: 1000;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  background: linear-gradient(#eee, #ddd);
  border-radius: 20px;
}

.dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}

.dropzone .dz-preview.dz-image-preview {
  background: #fff;
}

.dropzone .dz-preview.dz-image-preview .dz-details {
  transition: opacity .2s linear;
}

.dropzone .dz-preview .dz-remove {
  text-align: center;
  cursor: pointer;
  border: none;
  font-size: 14px;
  display: block;
}

.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline;
}

.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone .dz-preview .dz-details {
  z-index: 20;
  opacity: 0;
  text-align: center;
  color: #000000e6;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  font-size: 13px;
  line-height: 150%;
  position: absolute;
  top: 0;
  left: 0;
}

.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}

.dropzone .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
  background-color: #fffc;
  border: 1px solid #c8c8c8cc;
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  text-overflow: ellipsis;
  overflow: hidden;
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid #0000;
}

.dropzone .dz-preview .dz-details .dz-filename span, .dropzone .dz-preview .dz-details .dz-size span {
  background-color: #fff6;
  border-radius: 3px;
  padding: 0 .4em;
}

.dropzone .dz-preview:hover .dz-image img {
  filter: blur(8px);
  transform: scale(1.05);
}

.dropzone .dz-preview .dz-image {
  z-index: 10;
  border-radius: 20px;
  width: 120px;
  height: 120px;
  display: block;
  position: relative;
  overflow: hidden;
}

.dropzone .dz-preview .dz-image img {
  display: block;
}

.dropzone .dz-preview.dz-success .dz-success-mark {
  animation: 3s cubic-bezier(.77, 0, .175, 1) passing-through;
}

.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  animation: 3s cubic-bezier(.77, 0, .175, 1) slide-in;
}

.dropzone .dz-preview .dz-success-mark, .dropzone .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  background: #000c;
  border-radius: 50%;
  margin-top: -27px;
  margin-left: -27px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.dropzone .dz-preview .dz-success-mark svg, .dropzone .dz-preview .dz-error-mark svg {
  fill: #fff;
  width: 54px;
  height: 54px;
  display: block;
}

.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  transition: all .2s linear;
}

.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  transition: opacity .4s ease-in;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  animation: 6s infinite pulse;
}

.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  background: #000c;
  border: 3px solid #000c;
  border-radius: 10px;
  height: 20px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  left: 15%;
  right: 15%;
  overflow: hidden;
}

.dropzone .dz-preview .dz-progress .dz-upload {
  background: #fff;
  border-radius: 17px;
  width: 0;
  height: 100%;
  transition: width .3s ease-in-out;
  display: block;
  position: relative;
}

.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}

.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  opacity: 0;
  color: #fff;
  background: #b10606;
  border-radius: 8px;
  width: 140px;
  padding: .5em 1em;
  font-size: 13px;
  transition: opacity .3s;
  display: none;
  position: absolute;
  top: 130px;
  left: -10px;
}

.dropzone .dz-preview .dz-error-message:after {
  content: "";
  border-bottom: 6px solid #b10606;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: -6px;
  left: 64px;
}

.YUBJYq_panelImage {
  height: 100%;
  width: calc(100% - 20px) !important;
}

.YUBJYq_defaultPadding {
  padding: 20px;
}

.YUBJYq_shadow {
  box-shadow: .2px .3px 1.4px #00000009, .5px .8px 3.8px #0000000d, 1.2px 1.8px 9px #0001;
}

.YUBJYq_dropShadow {
  filter: drop-shadow(3px 4px 5px #00000026);
}

.YUBJYq_newDealButton {
  width: 120px;
  height: 50px;
  position: absolute;
  right: 0;
}

.YUBJYq_taskPageDropZone {
  border-radius: 10px;
  width: 124px;
  height: 54px;
  transition: border .24s ease-in-out;
  transform: translate(-4px, 1px);
  background-color: #0000 !important;
  border: 2px solid #fff !important;
}

.YUBJYq_taskPageDropZone:hover {
  color: #7e7e7e !important;
}

.YUBJYq_taskPageDropZone[class*="dz-drag-hover"] {
  border: 2px dashed #007bff !important;
}

.YUBJYq_taskPageDropZone [class*="dz-message"] {
  margin: 0 !important;
}

.YUBJYq_uploadIcon:before {
  pointer-events: none;
  transform: scale(3.1)translate(2px, 5px);
}

._8RP5ba_panelImage {
  height: 100%;
  width: calc(100% - 20px) !important;
}

._8RP5ba_defaultPadding {
  padding: 20px;
}

._8RP5ba_shadow {
  box-shadow: .2px .3px 1.4px #00000009, .5px .8px 3.8px #0000000d, 1.2px 1.8px 9px #0001;
}

._8RP5ba_dropShadow {
  filter: drop-shadow(3px 4px 5px #00000026);
}

._8RP5ba_modified {
  animation: none;
  animation-delay: calc(var(--grid-column) * 50ms) !important;
}

._8RP5ba_editable {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 10px;
  transition: background-color .3s;
  color: #00f !important;
}

._8RP5ba_editable:hover {
  background-color: #f2f4f7;
}

._8RP5ba_editable:active {
  background-color: #d9d9d9;
}

._8RP5ba_editHandIcon {
  color: #2075f4;
}

._8RP5ba_rowLabel {
  color: #102442;
  font-family: Hanken Grotesk;
}

._8RP5ba_rowLabel div[class*="title"] {
  font-size: 16px;
  font-weight: bold;
}

._8RP5ba_rowLabel div[class*="desc"] {
  font-size: 13px;
}

._8RP5ba_columnHeader {
  color: #667085;
  font-family: Hanken Grotesk;
  font-size: 16px;
  font-weight: bold;
}

._8RP5ba_rowValue {
  color: #102442;
  width: 100%;
  font-family: Hanken Grotesk;
  font-size: 16px;
  font-weight: bold;
}

._8RP5ba_rowValueBoolean {
  color: #102442;
  font-size: 32px;
}

._8RP5ba_source_page_entry {
  color: #2075f4;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #f2f4f7;
  border-radius: 25px;
  min-width: 40px;
  height: 30px;
  margin: 3px;
  padding: 2px 10px;
  font-size: 16px;
  font-weight: 500;
  transition: background-color .3s;
}

._8RP5ba_source_page_entry:hover {
  background-color: #d9d9d9 !important;
}

._8RP5ba_source_page_entry:active {
  background-color: #b3b3b3 !important;
}

.fejckW_editIndustry {
  width: 100%;
}

.fejckW_editIndustry * #fejckW_typeahead-industry-edit {
  transform: translateX(100%);
}

.SEVMuW_panelImage {
  height: 100%;
  width: calc(100% - 20px) !important;
}

.SEVMuW_defaultPadding {
  padding: 20px;
}

.SEVMuW_shadow {
  box-shadow: .2px .3px 1.4px #00000009, .5px .8px 3.8px #0000000d, 1.2px 1.8px 9px #0001;
}

.SEVMuW_dropShadow {
  filter: drop-shadow(3px 4px 5px #00000026);
}

.SEVMuW_gridContainer {
  --line-offset: calc(var(--panel-table-gap) / 2);
  --line-thickness: 2px;
  --line-color: red;
  --border-color: #eaecf0;
  grid-template-columns: [fieldName] var(--panel-table-label-column-width);
  grid-auto-columns: var(--panel-table-auto-row-width);
  gap: var(--panel-table-gap);
  display: grid;
  overflow: hidden;
}

.SEVMuW_grid_item {
  filter: none;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  background-color: #fff;
  padding: 8px 6px;
  position: relative;
}

.SEVMuW_grid_col_1 {
  --grid-column: 1;
  grid-column: 1;
}

.SEVMuW_grid_row_1 {
  --grid-row: 1;
  grid-row: 1;
}

.SEVMuW_grid_col_2 {
  --grid-column: 2;
  grid-column: 2;
}

.SEVMuW_grid_row_2 {
  --grid-row: 2;
  grid-row: 2;
}

.SEVMuW_grid_col_3 {
  --grid-column: 3;
  grid-column: 3;
}

.SEVMuW_grid_row_3 {
  --grid-row: 3;
  grid-row: 3;
}

.SEVMuW_grid_col_4 {
  --grid-column: 4;
  grid-column: 4;
}

.SEVMuW_grid_row_4 {
  --grid-row: 4;
  grid-row: 4;
}

.SEVMuW_grid_col_5 {
  --grid-column: 5;
  grid-column: 5;
}

.SEVMuW_grid_row_5 {
  --grid-row: 5;
  grid-row: 5;
}

.SEVMuW_grid_col_6 {
  --grid-column: 6;
  grid-column: 6;
}

.SEVMuW_grid_row_6 {
  --grid-row: 6;
  grid-row: 6;
}

.SEVMuW_grid_col_7 {
  --grid-column: 7;
  grid-column: 7;
}

.SEVMuW_grid_row_7 {
  --grid-row: 7;
  grid-row: 7;
}

.SEVMuW_grid_col_8 {
  --grid-column: 8;
  grid-column: 8;
}

.SEVMuW_grid_row_8 {
  --grid-row: 8;
  grid-row: 8;
}

.SEVMuW_grid_col_9 {
  --grid-column: 9;
  grid-column: 9;
}

.SEVMuW_grid_row_9 {
  --grid-row: 9;
  grid-row: 9;
}

.SEVMuW_grid_col_10 {
  --grid-column: 10;
  grid-column: 10;
}

.SEVMuW_grid_row_10 {
  --grid-row: 10;
  grid-row: 10;
}

.SEVMuW_grid_col_11 {
  --grid-column: 11;
  grid-column: 11;
}

.SEVMuW_grid_row_11 {
  --grid-row: 11;
  grid-row: 11;
}

.SEVMuW_grid_col_12 {
  --grid-column: 12;
  grid-column: 12;
}

.SEVMuW_grid_row_12 {
  --grid-row: 12;
  grid-row: 12;
}

.SEVMuW_grid_col_13 {
  --grid-column: 13;
  grid-column: 13;
}

.SEVMuW_grid_row_13 {
  --grid-row: 13;
  grid-row: 13;
}

.SEVMuW_grid_col_14 {
  --grid-column: 14;
  grid-column: 14;
}

.SEVMuW_grid_row_14 {
  --grid-row: 14;
  grid-row: 14;
}

.SEVMuW_grid_col_15 {
  --grid-column: 15;
  grid-column: 15;
}

.SEVMuW_grid_row_15 {
  --grid-row: 15;
  grid-row: 15;
}

.SEVMuW_grid_col_16 {
  --grid-column: 16;
  grid-column: 16;
}

.SEVMuW_grid_row_16 {
  --grid-row: 16;
  grid-row: 16;
}

.SEVMuW_grid_col_17 {
  --grid-column: 17;
  grid-column: 17;
}

.SEVMuW_grid_row_17 {
  --grid-row: 17;
  grid-row: 17;
}

.SEVMuW_grid_col_18 {
  --grid-column: 18;
  grid-column: 18;
}

.SEVMuW_grid_row_18 {
  --grid-row: 18;
  grid-row: 18;
}

.SEVMuW_grid_col_19 {
  --grid-column: 19;
  grid-column: 19;
}

.SEVMuW_grid_row_19 {
  --grid-row: 19;
  grid-row: 19;
}

.SEVMuW_grid_col_20 {
  --grid-column: 20;
  grid-column: 20;
}

.SEVMuW_grid_row_20 {
  --grid-row: 20;
  grid-row: 20;
}

.SEVMuW_grid_col_start {
  border-left: 1px solid var(--border-color);
}

.SEVMuW_grid_col_end {
  border-right: 1px solid var(--border-color);
}

.SEVMuW_disable_round_edges {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.SEVMuW_grid_row_last.SEVMuW_grid_col_start {
  border-bottom-left-radius: 10px;
}

.SEVMuW_grid_row_last.SEVMuW_grid_col_end {
  border-bottom-right-radius: 10px;
}

.SEVMuW_headerRow {
  background-color: var(--border-color);
  color: #667085;
  place-content: center stretch;
  font-family: Hanken Grotesk;
  font-weight: bold;
}

.yzAg9a_panelTableStyle {
  --panel-table-label-column-width: 500px;
  --panel-table-gap: 20px;
  padding-top: 10px !important;
}

.yzAg9a_panelTableStyle [class*="even_row"] {
  background-color: #fcfcfd;
  border: none !important;
}

.yzAg9a_panelTableStyle [class*="odd_row"] {
  background-color: #f1f3f6;
  border: none !important;
}

.yzAg9a_panelTableStyle [class*="rowValue"] {
  text-align: center;
}

.yzAg9a_panelTableCompanies [class*="grid_row_1 "] {
  background-color: #0000 !important;
}

.yzAg9a_companyHeaderElement {
  filter: drop-shadow(0 4px 10px #0000001a);
  background-color: #fff;
  border-radius: 20px;
  width: calc(100% - 40px);
  height: 200px;
  margin: 10px 20px;
}

.yzAg9a_companyNameLabel {
  color: #000;
  font-family: LustTextMedium;
  font-size: 20px;
}

.yzAg9a_panelTitle {
  background-color: #0000 !important;
}

.yzAg9a_panelTitle :after {
  content: "";
  background-image: linear-gradient(to right, #000 33%, #fff0 0%);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 10px 1px;
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

._Czy6G_panelImage {
  height: 100%;
  width: calc(100% - 20px) !important;
}

._Czy6G_defaultPadding {
  padding: 20px;
}

._Czy6G_shadow {
  box-shadow: .2px .3px 1.4px #00000009, .5px .8px 3.8px #0000000d, 1.2px 1.8px 9px #0001;
}

._Czy6G_dropShadow {
  filter: drop-shadow(3px 4px 5px #00000026);
}

._Czy6G_panelRowTitle {
  color: #000;
  padding: 10px;
  font-family: LustTextMedium;
  font-size: 22px;
  font-style: italic;
  font-weight: 300;
}

._Czy6G_panelTopBorder {
  border-top: 2px solid #eaecf0;
  border-left: 2px solid #eaecf0;
  border-right: 2px solid #eaecf0;
}

.YM63lW_scrollContainer {
  scrollbar-color: #eaecf0 transparent;
  scrollbar-width: thin;
  height: calc(100vh - 245px);
  margin-right: 8px;
  padding-right: 21px;
  overflow: hidden;
}

._63TkoG_panelImage {
  height: 100%;
  width: calc(100% - 20px) !important;
}

._63TkoG_defaultPadding {
  padding: 20px;
}

._63TkoG_shadow {
  box-shadow: .2px .3px 1.4px #00000009, .5px .8px 3.8px #0000000d, 1.2px 1.8px 9px #0001;
}

._63TkoG_dropShadow {
  filter: drop-shadow(3px 4px 5px #00000026);
}

._63TkoG_panel_shadow._63TkoG_panel:before {
  content: "";
  background-color: #0000;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  position: absolute;
  box-shadow: .2px .3px 1.4px #00000009, .5px .8px 3.8px #0000000d, 1.2px 1.8px 9px #0001;
}

._63TkoG_panel {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

._63TkoG_content {
  padding-top: 5px !important;
  padding-left: 8px !important;
}

._63TkoG_title {
  padding-left: 4px;
  font-family: LustTextItalic;
  font-size: 22px;
  font-weight: 500;
}

.USuRvW_panelImage {
  height: 100%;
  width: calc(100% - 20px) !important;
}

.USuRvW_defaultPadding {
  padding: 20px;
}

.USuRvW_shadow {
  box-shadow: .2px .3px 1.4px #00000009, .5px .8px 3.8px #0000000d, 1.2px 1.8px 9px #0001;
}

.USuRvW_dropShadow {
  filter: drop-shadow(3px 4px 5px #00000026);
}

.USuRvW_title {
  font-family: LustTextMedium;
  font-size: 40px;
}

.USuRvW_header {
  padding-top: 10px;
  background-color: #fcfcfd !important;
}

.USuRvW_seperatorFields {
  margin-left: 5px;
  margin-right: 5px;
  transform: translateY(2px);
  color: #d0d5dd !important;
}

.pezUqG_title {
  color: #667085;
  font-family: Hanken Grotesk;
  font-size: 15px;
  font-weight: 600;
}

.pezUqG_value {
  color: #667085;
  font-family: Hanken Grotesk;
  font-size: 15px;
}

.vcL-xa_switchButton {
  z-index: 10000000;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 10px !important;
}

.vcL-xa_draggable {
  cursor: col-resize !important;
}

.vcL-xa_dragIconStyle {
  z-index: 50000;
  position: absolute;
  transform: translate(-5px, 30px);
}

.LLS8Uq_chatMessage {
  width: 100%;
  min-height: 150px;
  padding: 20px;
}

.LLS8Uq_border {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
}

.K2Hpaa_messagesContainer {
  min-height: 1000px !important;
}

.hOenCq_container {
  z-index: 100000;
  background-color: "#EAEAEA";
  border-radius: 20px;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
}

.hOenCq_scrollFadeContent:before {
  content: "";
  background-image: linear-gradient(#fff 20%, #0000 100%);
  border-radius: 10px;
  width: 100%;
  height: 50px;
  display: inline-block;
  position: absolute;
  top: 0;
}

.hOenCq_messages {
  background-color: "#EAEAEA";
  height: calc(100% - 60px);
}

.hOenCq_input {
  flex-grow: 1;
  height: 100%;
  font-size: 20px;
}

.hOenCq_input input {
  background-color: #0000;
  outline: 0;
  width: 100%;
  margin-left: 10px;
  padding: 5px 10px;
  border: none !important;
  border-top: 1px solid #e0e0e0 !important;
}

.hOenCq_input input:focus {
  border: none !important;
  border-top: 1px solid #7c7c7c !important;
}

.hOenCq_sendButton {
  width: 100px;
  font-weight: bold;
  border-radius: 0 0 20px !important;
  font-family: Hanken Grotesk !important;
  font-size: 20px !important;
}

.hOenCq_inputLineOffset {
  transform: translateY(16px);
}

.xMsl9q_panelImage {
  height: 100%;
  width: calc(100% - 20px) !important;
}

.xMsl9q_defaultPadding {
  padding: 20px;
}

.xMsl9q_shadow {
  box-shadow: .2px .3px 1.4px #00000009, .5px .8px 3.8px #0000000d, 1.2px 1.8px 9px #0001;
}

.xMsl9q_dropShadow {
  filter: drop-shadow(3px 4px 5px #00000026);
}

.xMsl9q_panelRowDivier {
  background-color: #eaecf0;
  width: 100%;
  height: 10px;
}

.oAoGMG_panelImage {
  height: 100%;
  width: calc(100% - 20px) !important;
}

.oAoGMG_defaultPadding {
  padding: 20px;
}

.oAoGMG_shadow {
  box-shadow: .2px .3px 1.4px #00000009, .5px .8px 3.8px #0000000d, 1.2px 1.8px 9px #0001;
}

.oAoGMG_dropShadow {
  filter: drop-shadow(3px 4px 5px #00000026);
}

.oAoGMG_panelRowHeader {
  color: #fff;
  background-color: #243a5c;
  border-radius: 20px 20px 0 0;
  padding: 10px;
  font-family: LustTextMedium;
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
}

.oAoGMG_panelRowHeader > i {
  color: #cac7b7;
}

.oAoGMG_panelRowHeader > * {
  padding: 0 5px;
}

.ZOZyKW_panelImage {
  height: 100%;
  width: calc(100% - 20px) !important;
}

.ZOZyKW_defaultPadding {
  padding: 20px;
}

.ZOZyKW_shadow {
  box-shadow: .2px .3px 1.4px #00000009, .5px .8px 3.8px #0000000d, 1.2px 1.8px 9px #0001;
}

.ZOZyKW_dropShadow {
  filter: drop-shadow(3px 4px 5px #00000026);
}

.ZOZyKW_title {
  color: #243a5c;
  border-bottom: 1px dashed #d0d5dd;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: LustTextMedium;
  font-size: 22px;
  font-style: italic;
}

.ZOZyKW_desc {
  color: #101828;
  padding-top: 10px;
  padding-bottom: 20px;
  font-family: Hanken Grotesk;
  font-size: 16px;
}

.HLHa5a_container {
  padding-left: var(--main-container-side-padding);
  padding-right: var(--main-container-side-padding);
  padding-top: 15px;
  background-color: #fcfcfd !important;
}

.HLHa5a_tabsShadow {
  z-index: 10000;
  background: linear-gradient(#0000000d 0%, #fcfcfd 100%);
  width: 100%;
  height: 20px;
  position: absolute;
  top: 150px;
  left: 0;
  transform: translateY(45px);
}

.dealViewTabs {
  background-color: #fff;
  --bs-nav-underline-gap: 50px !important;
  --bs-nav-underline-link-active-color: #957f32 !important;
  --bs-emphasis-color: #957f32 !important;
  padding-left: var(--main-container-side-padding) !important;
  padding-right: var(--main-container-side-padding) !important;
}

.dealViewTabs.nav .nav-item .nav-link {
  color: #102442;
  background-color: #0000;
  font-family: Hanken Grotesk;
}

.dealViewTabs.nav .nav-item .nav-link:hover {
  border-bottom-color: #937c28a1;
}

.dealViewTabs.nav .nav-item .nav-link.active {
  border-bottom-color: #957f32;
}
/*# sourceMappingURL=index.c81adcf0.css.map */
